exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affiliates-js": () => import("./../../../src/pages/affiliates.js" /* webpackChunkName: "component---src-pages-affiliates-js" */),
  "component---src-pages-chakra-affirmations-down-js": () => import("./../../../src/pages/chakra-affirmations-down.js" /* webpackChunkName: "component---src-pages-chakra-affirmations-down-js" */),
  "component---src-pages-chakra-affirmations-up-js": () => import("./../../../src/pages/chakra-affirmations-up.js" /* webpackChunkName: "component---src-pages-chakra-affirmations-up-js" */),
  "component---src-pages-combo-2-js": () => import("./../../../src/pages/combo2.js" /* webpackChunkName: "component---src-pages-combo-2-js" */),
  "component---src-pages-combo-3-js": () => import("./../../../src/pages/combo3.js" /* webpackChunkName: "component---src-pages-combo-3-js" */),
  "component---src-pages-combo-js": () => import("./../../../src/pages/combo.js" /* webpackChunkName: "component---src-pages-combo-js" */),
  "component---src-pages-download-area-js": () => import("./../../../src/pages/download-area.js" /* webpackChunkName: "component---src-pages-download-area-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-weekly-guides-js": () => import("./../../../src/pages/weekly-guides.js" /* webpackChunkName: "component---src-pages-weekly-guides-js" */),
  "component---src-pages-weekly-star-guide-js": () => import("./../../../src/pages/weekly-star-guide.js" /* webpackChunkName: "component---src-pages-weekly-star-guide-js" */),
  "component---src-pages-welcome-back-js": () => import("./../../../src/pages/welcome-back.js" /* webpackChunkName: "component---src-pages-welcome-back-js" */)
}

